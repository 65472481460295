.App {
  text-align: center;
  min-height: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.Moreinfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: calc(10px + 2vmin);
}

.Pricing {
  padding: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
}

.pricing-title {
  margin-left: 15%;
  font-size: 34px;
  margin-bottom: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 50px;
}

.content {
  z-index: 10;
  height: 90vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

.content-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.moreinfotext {
  font-size: 16px;
  font-weight: 300;
  margin: 15px 55px;
  margin: 0;
  margin-bottom: 8px;
}

.arrow:hover {
  cursor: pointer;
}

.text {
  font-size: 20px;
  margin: 15px 55px;
}

.social-items {
  margin-top: 1em;
}

.social-item {
  padding: 10px;
}

.video-background, .img-background {
  background-image: url('./background-image.jpeg');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  height: 100%;
  width: 100%;
}

.img-background{ animation: changeBg 10s infinite; }
@keyframes changeBg{
   0%,100%  {background-image: url('./background-image.jpeg');}
   25% {background-image: url('./background-image2.jpeg');}
   50% {background-image: url('./background-image3.jpeg');}
   75% {background-image: url('./background-image4.jpeg');}
}

.video-foreground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -98;
  /* background-color: rgba(0, 0, 0, 0.5); */
  height: 100%;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: white;
  display: flex;
  margin: auto;
  justify-content: center;
  font-size: 1rem;
}